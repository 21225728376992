import axios from '@/plugins/http.js';
class organizaManagementAPI {
  getDeptList(params) {
    // 组织列表
    return axios.get(`/dept-list?status=${params}`);
  }
  addDept(params) {
    // 增加组织
    return axios.post(`/dept`, params);
  }
  updateDept(params) {
    // 更新组织信息
    return axios.put(`/dept`, params);
  }
  delDept(id) {
    return axios.delete(`/dept/${id}`);
  }
  getOrgList(params) {//带分页组织机构列表
    return axios.get(`/dept-page?${params}`);
  }
}

export default new organizaManagementAPI();
