<template>
  <div class="container">
    <el-row>
      <el-col :span="24">
        <span class="rgba_reset__tip">请为 {{editForm.realName}} {{editForm.username}} 用户重置密码</span>
      </el-col>
    </el-row>
    <el-form
      :model="pwdForm"
      ref="pwdForm"
      :rules="pwdRules"
      :inline="true"
      :label-position="labelPosition"
      :label-width="labelWidth"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="新密码" prop="password">
            <el-input v-model.trim="pwdForm.password" show-password />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="确认密码" prop="cofirmPwd">
            <el-input v-model.trim="pwdForm.cofirmPwd" show-password />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item class="rgba_btn">
        <el-button class="btn btn_blue" @click="savePwd('pwdForm')">提交</el-button>
        <el-button class="btn btn_cancle btn_normal" @click="canclePwd('pwdForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import userManageAPI from '@apis/backStageEndAPI/userManageAPI';
export default {
  name: 'resetPwd',
  props: ['editForm'],
  data() {
    const PWDPATTERN = /^[a-zA-Z0-9_-]{6,12}$/;
    var pwdValidate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'));
      } else if (!PWDPATTERN.test(value)) {
        return callback(new Error('6-12位由字母、数字、下划线、减号'));
      } else {
        return callback()
      }
    };
    var confirmValidate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请再次输入密码'));
      } else if (!PWDPATTERN.test(value)) {
        return callback(new Error('6-12位由字母、数字、下划线、减号'));
      } else if (value !== this.pwdForm.password) {
        return callback(new Error('两次输入密码不一致'));
      } else {
        return callback()
      }
    };
    return {
      pwdForm: {
        password: '',
        cofirmPwd: '',
      },
      pwdRules: {
        password: [{ required: true, validator: pwdValidate, trigger: 'blur' }],
        cofirmPwd: [{ required: true, validator: confirmValidate, trigger: 'blur' }],
      },
      labelPosition: 'true',
      labelWidth: '120px',
    };
  },
  methods: {
    savePwd(formName) {
      let param = {
        id: this.editForm.userId ,
        password: this.$md5(this.pwdForm.password) 
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          userManageAPI.reset(param).then(res => {
            this.$refs[formName].resetFields();
            this.$emit('closeResetModal', true);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    canclePwd(formName) {
      this.$refs[formName].resetFields();
      this.$emit('closeResetModal', true);
    },
  },
  beforeDestory() {},
};
</script>
<style lang="scss" scoped>
.rgba_btn {
  display: block;
  text-align: right;
}
.rgba_reset__tip {
  display: block;
  text-align: center;
  color: #7f7f7f;
}
</style>
