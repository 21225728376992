import axios from '@plugins/http.js';
class userManageAPI {
  getUserList(params) {
    // 用户列表
    return axios.get(`/user-list?${params}`);
  }
  getUser(id) {
    // 获取用户
    return axios.get(`/user?${id}`);
  }
  getRoleList(id) {
    // 获取用户角色列表
    return axios.get(`/user/${id}/role-list`);
  }
  addUser(params) {
    // 增加用户
    return axios.post(`/user`, params);
  }
  updateUser(params) {
    // 更新用户信息
    return axios.put(`/user`, params);
  }
  reset(params) {
    // 重置密码
    return axios.put(`/user/${params.id}/password`, params);
  }
  delUser(id) {
    // 删除用户
    return axios.delete(`/dept/${id}`);
  }
  delUserList(ids) {
    // 批量删除用户
    return axios.delete(`/user-list?userIds=${ids}`);
  }
  getAreaList(params) {
    // 获取地区数据列表
    return axios.get(`/area-list?${params}`)
  }
}

export default new userManageAPI();
