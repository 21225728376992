<template>
  <div class="container">
    <el-form
      ref="addForm"
      :model="addForm"
      :rules="addUserRules"
      :label-position="labelPosition"
      :label-width="labelWidth"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名" prop="realName">
            <el-input v-model="addForm.realName" maxlength="50" show-word-limit/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="addForm.phone"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="账号" prop="username">
            <el-input v-model="addForm.username" maxlength="50" show-word-limit/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="状态" prop="status">
            <el-select v-model="addForm.status" clearable placeholder="请选择状态">
              <el-option v-for="item in statusOptions" :key="item.id" :label="item.label" :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="所属区域" prop="areaCode">
            <!-- :options="dictData"  -->
            <el-cascader v-model="addForm.areaCode" :props="pageParams.place" clearable/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="所属组织" prop="deptId">
            <el-cascader v-model="addForm.deptId" :options="orgData" :props="orgProps" clearable/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="角色" prop="roleIds">
            <el-checkbox-group v-model="addForm.roleIds" @change="handleRolesChange">
              <el-checkbox
                v-for="item in roleData"
                :key="item.roleName"
                :label="item.roleName"
                :value="item.roleId"
                :disabled="item.roleName === '超级管理员' || item.roleName == '学员'"
              >
                {{ item.roleName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="isShow">
          <el-form-item label="审核/管理区域" prop="ruleAreaCode">
            <el-cascader v-model="addForm.ruleAreaCode" :props="pageParams.place" clearable/>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="isArea">
          <el-form-item label="审核/管理组织" prop="ruleDeptId">
            <el-cascader v-model="addForm.ruleDeptId" :options="orgData" :props="orgProps" clearable/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item class="rgba_btn">
        <el-button class="btn btn_blue" @click="save">提交</el-button>
        <el-button class="btn btn_cancle btn_normal" @click="cancle">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import userManageAPI from '@apis/backStageEndAPI/userManageAPI'
import CommonUtils from '@utils/index.js'
export default {
  name: 'operateUser',
  props: ['editForm', 'roleData', 'dictData', 'orgData'],
  watch: {
    editForm: {
      handler(val) {
        let res = []
        if (this.editForm.roleIds.length) {
          this.editForm.roleIds.split(',').forEach(item => {
            this.roleData.forEach(ele => {
              if (ele.roleId == item) {
                res.push(ele.roleName)
              }
            })
          })
        }
        this.addForm = Object.assign({}, this.editForm)
        this.addForm.roleIds = res
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      const REG_TEL = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      const val = value && REG_TEL.test(value)
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else if (!val) {
        callback(new Error('格式不正确'))
      } else {
        callback()
      }
    }
    return {
      statusOptions: [
        {
          label: '正常',
          value: '1',
        },
        {
          label: '冻结',
          value: '0',
        },
      ],
      orgProps: {
        value: 'deptId',
        label: 'deptName',
        children: 'children',
        checkStrictly: true,
        expandTrigger: 'click',
      },
      areaProps: {
        value: 'regionCode',
        label: 'regioName',
        children: '',
      },
      pageParams: {
        place: {
          lazy: true,
          value: 'regionCode',
          label: 'regioName',
          checkStrictly: true,
          expandTrigger: 'click',
          lazyLoad(node, resolve) {
            const {level} = node
            let params
            if (node.root) {
              params = {page: 1, pageSize: 50, parentId: node.level}
            } else {
              params = {page: 1, pageSize: 50, parentId: node.data.id}
            }
            userManageAPI.getAreaList(CommonUtils.parseToParams(params)).then(res => {
              if (res && !res.status) {
                resolve(res.dataList)
              } else {
              }
            })
          },
        },
      },
      labelPosition: 'right',
      labelWidth: '120px',
      addForm: {
        realName: '',
        phone: '',
        username: '',
        roleIds: [],
        areaCode: '',
        deptId: '',
        ruleAreaCode: '',
        ruleDeptId: '',
        status: '1',
      },
      addUserRules: {
        realName: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          {min: 2, max: 100, message: '超出长度', trigger: 'blur'},
        ],
        status: [{required: true, message: '请选择', trigger: 'change'}],
        phone: [{required: true, validator: validateMobile, trigger: 'blur'}],
        username: [{required: true, message: '请输入账号', trigger: 'blur'}],
        areaCode: [{required: true, message: '请选择', trigger: 'change'}],
        deptId: [{required: true, message: '请选择', trigger: 'change'}],
        roleIds: [{required: true, message: '请选择角色', trigger: 'change'}],
        ruleAreaCode: [{required: true, message: '请选择', trigger: 'change'}],
        ruleDeptId: [{required: true, message: '请选择', trigger: 'change'}],
      },
      isShow: false,
      isArea: false,
    }
  },
  methods: {
    handleRolesChange(val) {
      this.addForm.roleIds = val
      this.isShow = this.addForm.roleIds.includes('区域管理员') ||
        this.addForm.roleIds.includes('区域审核员');
      this.isArea = this.addForm.roleIds.includes('组织管理员') ||
        this.addForm.roleIds.includes('组织审核员');
    },
    save() {
      let params = this.addForm,
        rolesId = [],
        res = {}
      if (Array.isArray(params.areaCode)) {
        params.areaCode = params.areaCode[params.areaCode.length - 1]
      }
      if (Array.isArray(params.deptId)) {
        params.deptId = params.deptId[params.deptId.length - 1]
      }
      if (Array.isArray(params.ruleAreaCode)) {
        params.ruleAreaCode = params.ruleAreaCode[params.ruleAreaCode.length - 1]
      }
      if (Array.isArray(params.ruleDeptId)) {
        params.ruleDeptId = params.ruleDeptId[params.ruleDeptId.length - 1]
      }
      if (Array.isArray(params.roleIds)) {
        params.roleIds.forEach(ele => {
          this.roleData.forEach(item => {
            if (ele === item.roleName) {
              rolesId.push(item.roleId)
            }
          })
        })
      }
      this.$refs['addForm'].validate(async valid => {
        if (valid) {
          params.roleIds = rolesId.join(',')
          if (!params.userId) {
            res = await userManageAPI.addUser(params)
          } else {
            res = await userManageAPI.updateUser(params)
          }
          this.$refs['addForm'].resetFields()
          this.$emit('closeModal', true)
        }
      })
    },
    cancle() {
      this.$refs['addForm'].resetFields()
      this.$EventBus.$emit('closeModal', true)
    },
  },
}
</script>
<style lang="scss" scoped>
.rgba_btn {
  display: block;
  text-align: right;
}
::v-deep .el-select,
::v-deep .el-cascader {
  width: 100% !important;
}
</style>
