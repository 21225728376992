<template>
  <div class="container">
    <header-bar></header-bar>
    <div class="gl_filter bg_white">
      <el-form :model="form" :inline="true" class="form">
        <el-row>
          <el-col :span="6">
            <el-form-item label="账号" prop="username">
              <el-input v-model="form.username" placeholder="请输入账号" clearable maxlength="50" show-word-limit />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="姓名" prop="realName">
              <el-input v-model="form.realName" placeholder="请输入姓名" maxlength="50" clearable show-word-limit />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form.phone" clearable placeholder="请输入手机号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属区域" prop="areaCode">
              <el-cascader v-model="form.areaCode" :props="pageParams.place" clearable @change="placeChangeAction" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属组织" prop="deptId">
              <el-cascader v-model="form.deptId" :props="orgProps" :options="orgOptions" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="角色" prop="roleIds">
              <el-select multiple v-model="form.roleIds" clearable placeholder="请选择角色">
                <el-option
                  v-for="item in roleList"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态" prop="status">
              <el-select v-model="form.status" clearable placeholder="请选择状态">
                <el-option label="全部" value="all" />
                <el-option
                  v-for="item in statusOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-button icon="el-icon-plus" class="btn btn_orange" @click="add">新建</el-button>
              <el-button icon="el-icon-delete" class="btn btn_blue" @click="dele">删除</el-button>
              <el-button icon="el-icon-search" class="btn btn_blue" @click="find">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="table">
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column label="账号" prop="username" />
        <el-table-column label="姓名" prop="realName" />
        <el-table-column label="角色" prop="roleNames"></el-table-column>
        <el-table-column label="手机号" prop="phone" />
        <el-table-column label="所属区域" prop="areaName" />
        <el-table-column label="所属组织" prop="deptName" />
        <el-table-column label="账号状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === '1'">正常</span>
            <span v-else>冻结</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="operate color_blue" @click="edit(scope)">编辑</span>
            <span class="operate color_blue" @click="reset(scope)">重置密码</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <pagination
      v-if="total > 0"
      :pageSize="form.pageSize"
      :total="total"
      @pageChange="pageChange"
      @sizeChange="sizeChange"
      ref="pageRef"
    />

    <el-dialog
      class="dialog_wrapper"
      :title="userDiaTitle"
      :visible.sync="addUserVisible"
      :close-on-click-modal="closeModal"
      width="800px"
    >
      <operate-user
        @closeModal="closeUserEvent"
        :edit-form="editForm"
        :role-data="roleList"
        :dict-data="dictOptions"
        :org-data="orgOptions"
      />
    </el-dialog>

    <el-dialog
      class="dialog_wrapper"
      title="重置密码"
      :visible.sync="resetVisible"
      :close-on-click-modal="closeModal"
      width="450px"
    >
      <reset-pwd :edit-form="editForm" @closeResetModal="closeResetEvent" />
    </el-dialog>
  </div>
</template>

<script>
import headerBar from '@comps/backStageComponent/layout/headerBar/headerBar'
import Pagination from '@comps/backStageComponent/pagination'
import operateUser from './operateUser.vue'
import resetPwd from './resetPwd.vue'
import userManageAPI from '@apis/backStageEndAPI/userManageAPI'
import orgAPI from '@apis/backStageEndAPI/organizaManagementAPI'
import roleAPI from '@apis/backStageEndAPI/roleManagement'
import CommonUtils from '@utils/index.js'

export default {
  name: 'userManage',
  components: {
    headerBar,
    Pagination,
    operateUser,
    resetPwd,
  },
  data() {
    return {
      treeProps: {
        value: 'regionCode',
        label: 'regioName',
        children: '',
      },
      pageParams: {
        place: {
          lazy: true,
          value: 'regionCode',
          label: 'regioName',
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node
            let params
            if (node.root) {
              params = { page: 1, pageSize: 50, parentId: node.level }
            } else {
              params = { page: 1, pageSize: 50, parentId: node.data.id }
            }
            userManageAPI.getAreaList(CommonUtils.parseToParams(params)).then(res => {
              if (res && !res.status) {
                resolve(res.dataList)
              } else {
              }
            })
          },
        },
      },
      form: {
        areaCode: '',
        deptId: '',
        page: 1,
        pageSize: 10,
        phone: '',
        realName: '',
        roleIds: [],
        status: '',
        username: '',
      },
      labelPosition: 'true',
      labelWidth: '120px',
      closeModal: false,
      resetVisible: false,
      roles: [],
      userDiaTitle: '',
      editForm: {},
      addUserVisible: false,
      dictOptions: [],
      orgOptions: [],
      roleList: [],
      statusOptions: [
        {
          label: '正常',
          value: '1',
        },
        {
          label: '冻结',
          value: '0',
        },
      ],
      tableData: [],
      multipleSelection: [],
      orgProps: {
        value: 'deptId',
        label: 'deptName',
        children: 'children',
        checkStrictly: true,
        expandTrigger: 'hover',
      },
      total: 0,
    }
  },
  mounted() {
    this.getAreaList()
    this.getOrgList()
    this.getRoleList()
    this.search()
    this.$EventBus.$on('closeModal', val => {
      this.closeUserEvent()
    })
  },
  methods: {
    find() {
      this.form.page = 1
      this.$refs.pageRef.page = 1
      this.search()
    },
    sizeChange(val) {
      this.form.pageSize = val
      this.search()
    },
    pageChange(val) {
      this.form.page = val
      this.search()
    },
    async getRoleList() {
      let res = await roleAPI.getRoleList()
      this.roleList = res
    },
    async getOrgList() {
      let status = ''
      let res = await orgAPI.getDeptList(status)
      this.orgOptions = res
    },
    async getAreaList() {
      const params = {
        page: 1,
        pageSize: 5000,
      }
      let res = await userManageAPI.getAreaList(CommonUtils.parseToParams(params))
      this.dictOptions = res.dataList
    },
    async search() {
      const params = CommonUtils.parseToParams(this.form)
      let res = await userManageAPI.getUserList(params)
      this.tableData = res.dataList
      this.total = res.rowCount
    },
    closeUserEvent(val) {
      this.search()
      this.addUserVisible = false
    },
    closeResetEvent(val) {
      this.resetVisible = false
    },
    handleRolesChange(val) {},
    reset(scope) {
      this.editForm = Object.assign({}, scope.row)
      this.resetVisible = true
    },
    add() {
      this.editForm
      this.userDiaTitle = '新建用户'
      if (!this.editForm.roleIds) {
        this.editForm.roleIds = []
      }
      this.editForm = Object.assign({}, this.form)
      Object.keys(this.editForm).forEach(item => {
        this.editForm[item] = ''
        if(item == 'roleIds') {
          this.editForm['roleIds'] = '2'
        }
      })
      this.addUserVisible = true
    },
    edit(scope) {
      this.userDiaTitle = '编辑用户'
      this.editForm = Object.assign({}, scope.row)
      if (!this.editForm.roleIds) {
        this.editForm.roleIds = []
      }
      this.addUserVisible = true
    },
    async dele() {
      let [param, params] = ['', []],
        rows = this.multipleSelection
      if (rows.length === 0) {
        this.$message.warning('请先选择一条记录')
        return
      } else if (rows.length === 1) {
        rows.forEach(item => {
          param = item.userId
        })
        await userManageAPI.delUser(param)
        this.search()
      } else {
        rows.forEach(item => {
          params.push(item.userId)
        })
        await userManageAPI.delUserList(params.join(','))
      }
      this.search()
    },
    freeze() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择一条记录')
      } else {
      }
    },
    unfreeze() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择一条记录')
      } else if (this.multipleSelection.length === 1) {
      } else {
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    placeChangeAction(val) {
      if (val.length != 0) {
        this.form.areaCode = val[val.length - 1]
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.gl_filter {
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 12px;
}
::v-deep .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-cascader {
  position: relative;
  width: 100%;
  font-size: 14px;
  line-height: 40px;
}
</style>
